.user-image {
  height: 30px;
  width: 30px;
  border-radius: 20px;
}

.sentiment-buttons button {
  background-color: white !important;
}

.sentiment-buttons .button-selected {
  border: solid gray 1px !important;
}

.searchDropdown {
  background-color: #fff;
  border: 1px solid #8290a4;
  border-radius: 4px;
  margin: 0 !important;
  max-height: 360px;
  overflow: scroll;
  position: absolute;
  top: 29px;
  left: 0;
  right: 69px;
  z-index: 500;
  width: 100%;

  .searchHeader {
    border-bottom: 1px solid #8290a4;
    padding: 4px;
    text-align: right;
  }

  .searchItem {
    cursor: pointer;
    display: block;
    padding: 6px 14px;

    &:hover {
      background-color: #0073c7;
      color: #fff;
    }
  }
}
