.icon-font {
  font-size: 60px;
}

.success-container {
  padding: 10px;
  color: white !important;
}

.white-font {
  color: white !important;
}

.light-blue-font {
  color: #e6f9fb !important;
}
