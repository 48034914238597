.header.iframe {
  display: none;
}

.header {
  align-items: center;
  background-color: rgb(37, 40, 41);
  display: flex;
  justify-content: space-between;
  padding: 8px 0;

  .logo {
    display: flex;
    padding: 0 16px;
    text-decoration: none;
    width: fit-content;

    &:hover {
      text-decoration: none;
    }

    &__image {
      background: left center / contain no-repeat url(./../../logo.png);
      height: 34px;
      width: 200px;
      margin-right: 37px;
    }

    &__title {
      color: #fff;
      margin-left: 16px;
      margin-top: 1%;
    }
  }

  .search {
    align-items: center;
    border: 1px solid rgb(221, 219, 218);
    border-radius: 4px;
    color: rgb(8, 7, 7);
    display: flex;
    flex: 0 1 17.5rem;
    min-width: 285px;
    font-family: "Amazon Ember", Arial, sans-serif;

    .spinner {
      display: inline-block;
      height: 32px;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 0;
      width: 32px;
    }

    .select {
      align-items: center;
      background-color: #fff;
      border: 0;
      border-right: 1px solid rgb(221, 219, 218);
      border-radius: 4px 0 0 4px;
      color: rgb(112, 110, 107);
      cursor: pointer;
      display: flex;
      font-size: 13px;
      height: 32px;
      padding: 0 12px 0 12px;

      .current-selection {
        padding-right: 12px;
      }
    }

    .options {
      background-color: #fff;
      border: 1px solid rgb(221, 219, 218);
      border-radius: 4px;
      box-shadow: 0 2px 7px rgba(0, 0, 0, 0.07);
      color: rgb(62, 62, 60);
      font-size: 14px;
      padding: 4px 0;
      position: absolute;
      top: 43px;
      z-index: 99;

      .option {
        cursor: pointer;
        padding: 8px 16px;

        &:hover {
          background-color: rgb(243, 242, 242);
        }
      }
    }

    input {
      border: 0;
      border-radius: 0 4px 4px 0;
      box-sizing: border-box;
      font-size: 13px;
      flex-grow: 1;
      height: 32px;
      padding: 0 0 0 24px;
      width: 100%;

      &:focus {
        border-color: rgb(21, 137, 238);
        outline: 0;
      }
    }

    .search-results {
      background-color: #fff;
      border: 1px solid rgb(221, 219, 218);
      border-radius: 4px;
      box-shadow: 0 2px 7px rgba(0, 0, 0, 0.07);
      color: rgb(62, 62, 60);
      font-size: 14px;
      padding: 4px 0;
      position: absolute;
      top: 34px;
      width: 100%;
      z-index: 99;

      .search-results-item {
        cursor: pointer;

        a {
          color: rgb(8, 7, 7);
          display: block;
          padding: 8px 16px;
          text-decoration: none;

          &:hover {
            text-decoration: none;
          }
        }

        &:hover {
          background-color: rgb(243, 242, 242);
        }

        .type {
          color: rgb(62, 62, 60);
          font-size: 13px;
          line-height: 15px;
          text-transform: capitalize;
        }
      }
    }
  }

  .user {
    align-items: center;
    color: #fff;
    display: flex;
    min-width: 140px;

    .image {
      border-radius: 32px;
      display: inline-block;
      height: 32px;
      margin: 0 16px;
      width: 32px;
    }
  }
}

.search {
  align-items: center;
  border: 1px solid rgb(221, 219, 218);
  border-radius: 4px;
  color: rgb(8, 7, 7);
  display: flex;
  flex: 0 1 17.5rem;
  min-width: 285px;
  font-family: "Amazon Ember", Arial, sans-serif;

  .spinner {
    display: inline-block;
    height: 32px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    width: 32px;
  }

  .select {
    align-items: center;
    background-color: #fff;
    border: 0;
    border-right: 1px solid rgb(221, 219, 218);
    border-radius: 4px 0 0 4px;
    color: rgb(112, 110, 107);
    cursor: pointer;
    display: flex;
    font-size: 13px;
    height: 32px;
    padding: 0 12px 0 12px;

    .current-selection {
      padding-right: 12px;
    }
  }

  .options {
    background-color: #fff;
    border: 1px solid rgb(221, 219, 218);
    border-radius: 4px;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.07);
    color: rgb(62, 62, 60);
    font-size: 14px;
    padding: 4px 0;
    position: absolute;
    top: 43px;
    z-index: 99;

    .option {
      cursor: pointer;
      padding: 8px 16px;

      &:hover {
        background-color: rgb(243, 242, 242);
      }
    }
  }

  input {
    border: 0;
    border-radius: 0 4px 4px 0;
    box-sizing: border-box;
    font-size: 13px;
    flex-grow: 1;
    height: 32px;
    padding: 0 0 0 24px;
    width: 100%;

    &:focus {
      border-color: rgb(21, 137, 238);
      outline: 0;
    }
  }

  .search-results {
    background-color: #fff;
    border: 1px solid rgb(221, 219, 218);
    border-radius: 4px;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.07);
    color: rgb(62, 62, 60);
    font-size: 14px;
    padding: 4px 0;
    position: absolute;
    top: 34px;
    width: 100%;
    z-index: 99;

    .search-results-item {
      cursor: pointer;

      a {
        color: rgb(8, 7, 7);
        display: block;
        padding: 8px 16px;
        text-decoration: none;

        &:hover {
          text-decoration: none;
        }
      }

      &:hover {
        background-color: rgb(243, 242, 242);
      }

      .type {
        color: rgb(62, 62, 60);
        font-size: 13px;
        line-height: 15px;
        text-transform: capitalize;
      }
    }
  }
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;

  border-top: 7px solid rgb(112, 110, 107);
}
