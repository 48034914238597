.vector-widget {
  width: 438px;
  height: 416px;

  .status-widget {
    background-color: #e6f9fa !important;
  }

  .heading-font {
    font-size: 14px !important;
  }

  .feature-row {
    border-radius: 8px;
    overflow: hidden;
  }

  .status-container {
    min-width: 50px !important;
    font-size: 14px !important;
  }

  .status-container p {
    color: #232f3e !important;
    font-size: 14px !important;
  }

  .status-container .statusIndicator {
    height: 12px;
    width: 12px;
  }

  .light-font {
    color: #686d6d !important;
    letter-spacing: 0.2px !important;
    font-size: 14px !important;
  }

  .row-opacity-1 {
    opacity: 1 !important;
  }

  .row-opacity-5 {
    opacity: 0.5 !important;
  }

  .bold-font {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #232f3e !important;
  }

  .font-12 {
    font-size: 12px !important;
  }
}
