.page {
  align-items: center;
  display: flex;
  height: calc(100vh - 90px);
  justify-content: center;
}

.error-container {
  align-items: center;
  display: flex;
  min-height: 100px;
  height: 100%;
  justify-content: center;
}
